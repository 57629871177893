import React from "react";
import "./about.css";
import SocialContact from "../../common/social-contact/index";
function About() {
  return (
    <div className="about">
      <div className="about-top">
        <div className="about-info">
          I am,
         <br /> <span className="info-name">Doston Hamraqulov</span>.
         <br /> <b>Freelancer</b>, Software Engineer and Tester
        </div>
        <div className="about-photo">
          <img
            src={require("../../../assets/coding.png")}
            className="picture"
			alt={'about icon'}
          />
        </div>
      </div>
      <SocialContact />
    </div>
  );
}

export default About;
