import React from 'react';
import './work.css';
import Separator from '../../common/separator';
import {WorkData} from '../../data/work';
import WorkCard from './work-card';

function Work() {
    const data = WorkData;
    return (
        <div className='work'>
            <Separator/>
            <label className='section-title'>Expériences</label>
            <div className='work-list'>
                {data.map((item) => {
                    return <WorkCard key={item.company} item={item}/>;
                })}
            </div>
        </div>
    );
}

export default Work;
