export const SkillsData = [
    {
        type: 'Front-End',
        list: [
            {
                name: 'ReactJS',
                icon: <i className='devicon-react-original colored'></i>
            },
            {
                name: 'HTML5',
                icon: <i className='devicon-html5-plain colored'></i>
            },
            {
                name: 'JavaScript',
                icon: <i className='devicon-javascript-plain colored'></i>
            },
            {
                name: 'TypeScript',
                icon: <i className='devicon-typescript-plain colored'></i>
            },
        ],
    },
    {
        type: 'Back-End',
        list: [
            {
                name: 'Java',
                icon: <i className='devicon-java-plain colored'></i>
            },
            {
                name: 'Spring',
                icon: <i className='devicon-spring-plain colored'></i>
            },
            {
                name: 'Python',
                icon: <i className='devicon-python-plain colored'></i>
            },
            {
                name: 'PostgreSQL',
                icon: <i className='devicon-postgresql-plain colored'></i>
            }
        ],
    },
    {
        type: 'Other Skills',
        list: [
            {
                name: 'Firebase',
                icon: <i className='devicon-firebase-plain colored'></i>
            },
            {
                name: 'Bootstrap',
                icon: <i className='devicon-docker-plain colored'></i>
            },
            {
                name: 'AWS',
                icon: <i className='devicon-amazonwebservices-original colored'></i>
            },
            {
                name: 'Jenkins',
                icon: <i className='devicon-jenkins-line colored'></i>
            },
        ],
    },
];
