import React from 'react';
import './project-card.scss';
import ImageGallery from 'react-image-gallery';

function ProjectCard({project}) {

    return (
        <div className='project-card'>
            <div className='project-info'>
                <label className='project-title'>{project.title}</label>
                <div className='project-links'>
                    {project.demo && (
                        <a className='project-link' href={project.demo}>
                            <div className='link-button'>
                                <i className='fi-rr-globe'></i>Demo
                            </div>
                        </a>
                    )}
                    {project.github && (
                        <a className='project-link' href={project.github}>
                            <div className='link-button'>
                                <i className='devicon-github-original colored'></i>Github
                            </div>
                        </a>
                    )}
                </div>
                <p>{project.about}</p>
                <div className='project-tags'>
                    {project.tags.map((tag) => {
                        return <label key={tag} className='tag'>{tag}</label>;
                    })}
                </div>
            </div>
            <ImageGallery
                showPlayButton={false}
                items={project.images}/>
        </div>
    );
}

export default ProjectCard;
