import React from 'react';
import {SocialData} from '../../data/social';
import './social-contact.scss';

function SocialContact() {
    const data = SocialData;
    return (
        <div className='social-contact'>
            {data.map((item) => {
                return (
                    <a key={item.link}
                       href={item.link}
                       target={'_blank'}
                       rel='noreferrer'>
                        <div className='social-icon-div'>
                            {item.iconSvg &&
                                item.iconSvg
                            }
                            {item.icon &&
                                <img src={item.icon} className='social-icon' alt={'social icon'}/>
                            }
                        </div>
                        <div className={'social-name'}>
                            {item.platform}
                        </div>
                    </a>
                );
            })}
        </div>
    );
}

export default SocialContact;
