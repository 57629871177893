export const ProjectData = [
    {
        id:1,
        title:'Zakovat savollari',
        about: 'This is very large dynamic web application that I have been developing in Spring Boot with Single-Oauth options, database, and complex data operations. The web app is used as king of quiz and has a lot of features.',
        tags:['Spring Boot', 'Javascript', 'Bootstrap', 'Spring Security', 'AWS'],
        demo:'https://www.zakovat-savollari.com/',
        images: [
			{
				original: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-1.2.png',
				thumbnail: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-1.2.png'
			},
			{
				original: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-1.1.png',
				thumbnail: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-1.1.png'
			},
			{
				original: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-1.3.png',
				thumbnail: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-1.3.png'
			},
			{
				original: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-1.4.png',
				thumbnail: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-1.4.png'
			},
			{
				original: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-1.5.png',
				thumbnail: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-1.5.png'
			},
			{
				original: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-1.6.png',
				thumbnail: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-1.6.png'
			}
		]
    },
	{
		id:2,
		title:'T-Dispatch',
		about: 'Web application in React that is used as start-up company to show their service portfolio and open their services to clients via the web app.',
		tags:['ReactJS', 'TypeScript', 'HTML', 'CSS', 'Firebase'],
		url:'https://www.t-dispatch.com/',
		demo:'https://www.t-dispatch.com/',
		images: [
			{
				original: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-2.1.png',
				thumbnail: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-2.1.png'
			},
			{
				original: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-2.2.png',
				thumbnail: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-2.2.png'
			},
			{
				original: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-2.3.png',
				thumbnail: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-2.3.png'
			},
			{
				original: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-2.4.png',
				thumbnail: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-2.4.png'
			},
			{
				original: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-2.5.png',
				thumbnail: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-2.5.png'
			}
		]
	},
	{
		id:3,
		title:'Barso.uz',
		about: 'Another web application in React that is used to show their service portfolio and products. The web app is multilingual and responsive app.',
		tags:['ReactJS', 'TypeScript', 'HTML', 'CSS', 'Firebase'],
		url:'https://www.barso.uz/',
		demo:'https://www.barso.uz/',
		images: [
			{
				original: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-3.1.png',
				thumbnail: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-3.1.png'
			},
			{
				original: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-3.2.png',
				thumbnail: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-3.2.png'
			},
			{
				original: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-3.3.png',
				thumbnail: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-3.3.png'
			},
			{
				original: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-3.4.png',
				thumbnail: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-3.4.png'
			},
			{
				original: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-3.5.png',
				thumbnail: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-3.5.png'
			},
			{
				original: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-3.6.png',
				thumbnail: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-3.6.png'
			}
		]
	},
	{
		id:4,
		title:'krill-lotin',
		about: 'Krill-lotin translator is used to convert letters written in Uzbek cyrillic letters to uzbek latin letters.',
		tags:['HTMl5', 'jQuery', 'JavaScript', 'CSS', 'Firebase'],
		url:'https://krill-lotin.web.app/',
		demo:'https://krill-lotin.web.app/',
		images: [
			{
				original: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-4.1.png',
				thumbnail: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-4.1.png'
			},
			{
				original: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-4.2.png',
				thumbnail: 'https://www.zakovat-savollari.com/drive-images/dosha/projects-4.2.png'
			}
		]
	},
	{
		id:5,
		title:'BINUV Web app',
		about: 'A web application was developed in Spring Boot for the simulated Startup experience course',
		tags:['Spring Boot', 'PostgresSQL', 'Thymeleaf', 'Restful services', 'Heroku'],
		url:'https://binuv.com/',
		demo:'https://binuv.com/',
		images: [
			{
				original: 'https://www.zakovat-savollari.com/drive-images/dosha/projects_5.1.png',
				thumbnail: 'https://www.zakovat-savollari.com/drive-images/dosha/projects_5.1.png'
			},
			{
				original: 'https://www.zakovat-savollari.com/drive-images/dosha/projects_5.2.png',
				thumbnail: 'https://www.zakovat-savollari.com/drive-images/dosha/projects_5.2.png'
			},
			{
				original: 'https://www.zakovat-savollari.com/drive-images/dosha/projects_5.3.png',
				thumbnail: 'https://www.zakovat-savollari.com/drive-images/dosha/projects_5.3.png'
			},
			{
				original: 'https://www.zakovat-savollari.com/drive-images/dosha/projects_5.4.png',
				thumbnail: 'https://www.zakovat-savollari.com/drive-images/dosha/projects_5.4.png'
			},
			{
				original: 'https://www.zakovat-savollari.com/drive-images/dosha/projects_5.5.png',
				thumbnail: 'https://www.zakovat-savollari.com/drive-images/dosha/projects_5.5.png'
			}
		]
	},
];
