import React from "react";
import "./home.scss";
import Header from "../header/index";
import Body from "../body/index";
import Footer from "../footer/index";
import ReCAPTCHA from 'react-google-recaptcha';

export function createCaptureCookie() {
    const minutesToExpire = 1000;
    let date = new Date();
    date.setTime(date.getTime() + (minutesToExpire * 60 * 1000));
    document.cookie = process.env.REACT_APP_CAPTURE_COOKIE_NAME + " = " + process.env.REACT_APP_CAPTURE_COOKIE_VALUE + "; expires = " + date.toString();
}

export function getCaptureValue() {
    const regex = new RegExp(process.env.REACT_APP_CAPTURE_COOKIE_NAME + '=([^;]+)');
    const cookie = document.cookie.match(regex);
    return !!cookie ? cookie[1] : '';
}


class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {isCaptureSucceeded: false};

        if (getCaptureValue() === process.env.REACT_APP_CAPTURE_COOKIE_VALUE) {
            this.state = {isCaptureSucceeded: true};
        }

        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
        if (value !== undefined && value.length > 10) {
            this.setState({isCaptureSucceeded: true});
            createCaptureCookie();
        }
    }

    render() {
        return (<div className="home">

                {!this.state.isCaptureSucceeded &&
                    <div className={'capture'}>
                        <h3>
                            Please, complete capture to see the content.
                        </h3>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_SITE_KEY}
                            onChange={this.onChange}
                        />
                    </div>

                }

                {this.state.isCaptureSucceeded &&
                    (<div>
                        <div>
                            <Header/>
                        </div>
                        <div>
                            <Body/>
                        </div>
                        <div>
                            <Footer/>
                        </div>
                    </div>)
                }
            </div>
        );
    }
}

export default Home;
